/* PAGE */

body {
    font-family: Open Sans, sans-serif;
    background: #ccc;
}

#page-wrap {
    background: #fff;
}


/* TEXT */

h1, h3 {
    font-family: Open Sans Condensed, sans-serif;
}

h1 {
    font-size: 56px;
    font-weight: 700;
    text-align: center;
}

h2 {
    font-size: 24px;
    line-height: 30px;
}

h3 {
    font-weight: 600;
}


/* IMAGES */

.header-image {
    height: 400px;
    overflow: hidden;
    z-index: -1;
    background-image: url(/images/header.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

#logo {
    width: 300px;
}


/* CONTENT */

#content-wrap {
    padding: 60px 0 130px 0;
}

#content-left, #content-right {
    margin: 30px 0 0 0;
}

#content-left-subhead {
    margin-bottom: 25px;
}

#content-right {
    text-align: center;
}

#btn-findCar {
    margin-top: 30px;
    font-size: 26px;
    background: #cc0000;
    border: #cc0000;
}

#slogan {
    margin-bottom: 0;
}


/* FOOTER */

#page-footer {
    margin-top: 30px;
    color: #aaa;
    font-size: 14px;
}

#page-footer-copyright {
    text-align: center;
    margin-top: 10px;
}

.connect-btns i {
    font-size: 16px;
    line-height: 2.2em;
    background-color: #aaa;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 9px;
    margin-top: 0;
    color: #fff;
    width: 34px;
    height: 34px;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.connect-btns li:nth-of-type(2) i {
    background-color: #aaa;
}

.connect-btns li:nth-of-type(3) i {
    background-color: #aaa;
    color: #fff;
    font-size: 19px;
    line-height: 1.8em;
}

.connect-btns li:nth-of-type(4) i {
    background-color: #aaa;
}

.connect-btns li:nth-of-type(5) i {
    background-color: #aaa;
    line-height: 2.15em;
}

.connect-btns li a:hover i,
.connect-btns li a:active i {
    background-color: #444444;
    color: #fff;
}


/* RESPONSIVE */

@media screen and (max-width: 480px) {

    .header-image {
        display: none;
    }

    #content-wrap {
        padding: 20px 0 20px 0;
        margin: auto;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 20px;
        line-height: inherit;
    }

    #btn-findCar {
        font-size: 20px;
        margin-top: 20px;
    }

    #page-footer-copyright {
        text-align: center;
        font-size: 12px;
    }

    #page-footer-social {
        text-align: center;
        margin-top: 10px;
    }
}
